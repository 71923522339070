<template>
    <!-- header section -->
	<header v-if="checkForHeaderShow()" :class="excludedRoutes.includes($route.name) ? 'login-trans': 'heading-section'" loading="lazy">
		<Header></Header>
	</header>

    <router-view @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc"/>

      <!-- header section -->
    <footer class="footer-menu" loading="lazy">
      <Footer v-if="!footerExcludedRoutes.includes($route.name)" @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc" ></Footer>
    </footer>
	<SideMenu @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc" />
	<MyBetSideMenu v-show="checkIsLogin()" @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc" />
	<!-- <ChatComponent @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc"></ChatComponent> -->
	<GotoTop/>

     <div :class="{ show: showSuccessModal }" class="successfully-wrapper" style="z-index: 9999999999999999;"
    ref="successmodal" id="successfullyModal">
    <SuccessModal :message="successMsg" />
  </div>

  <div :class="{ show: showErrorModal }" style="z-index: 9999999999999999;" class="successfully-wrapper"
    id="wrongtoaster">
    <ErrorModal :message="errorMsg" />
  </div>

  	<GamesPop1 @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc" />
	<GamesPop2 @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc" />

	<LowBalanceRemainder @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc" />

</template>

<script>
import MyBetSideMenu from '@/shared/components/header/MyBetSideMenu.vue'
import SideMenu from '@/shared/components/header/SideMenu.vue'
import Header from '@/shared/components/header/Header.vue'
import Footer from '@/shared/components/footer/Footer.vue'
import SignUp from '@/modules/authorization/views/SignUp.vue'
import SuccessModal from '@/shared/components/modal/SuccessModal.vue';
import ErrorModal from '@/shared/components/modal/ErrorModal.vue';
import GamesPop1 from './shared/components/modal/GamesPop1.vue';
import GamesPop2 from './shared/components/modal/GamesPop2.vue';
import LowBalanceRemainder from '@/shared/components/modal/LowBalanceRemainder.vue'
// import ChatComponent from '@/modules/chat/views/ChatComponent.vue';
import GotoTop from '@/shared/components/footer/GotoTop.vue';

import moment from 'moment';
import { AUTH_TOKEN } from "./shared/constants/cookies-const";
import api from '@/shared/services/api';
import sportCount_api from '@/shared/services/sportcount-api';
import * as apiName from '@/shared/services/urls';
import { mapGetters } from 'vuex';
import axios from 'axios';
import { setHeaders } from '@/shared/services/headers';
import { callFavouriteApis } from '@/shared/services/common-services';
import socketService from "./socket/sport-socket-service";
import captchaApi from "./modules/authorization/services/captcha-api";
import {Modal} from "bootstrap"
import { useReCaptcha } from 'vue-recaptcha-v3'
import { provide } from 'vue'

export default {
  name: 'App',
  components: {
    Header,
    Footer,
    SignUp,
    SuccessModal,
    ErrorModal,
	GamesPop1,
	GamesPop2,
	SideMenu,
	MyBetSideMenu,
	LowBalanceRemainder,
	// ChatComponent,
	GotoTop

  },
  data(){
        return{
            excludedRoutes:[
                'login',
                'sign-up',
                'forget-password',
            ],
			footerExcludedRoutes:[
				'login',
                'sign-up',
                'forget-password',
				'MatchDetails'
			],
            onLine: navigator.onLine,
            showBackOnline: false,
            showSuccessModal: false,
            successMsg: "",
            showErrorModal: false,
            errorMsg: "",
            refreshInit: false,
            scY: 0,
            scTimer: 0,
			LowBalanceModal:null,
        }
    },
	setup() {
		const { executeRecaptcha, recaptchaLoaded } = useReCaptcha();

		const recaptcha = async (type) => {
			await recaptchaLoaded();
			const token = await executeRecaptcha(type);
			return token;
		};

		provide('recaptcha', recaptcha);

		return {};
	},
    computed: {
		...mapGetters({
			captchaData: 'captchaData', siteSettings: 'siteSettings', eventDetailHideSections: 'eventDetailHideSections'
		}),
		routePath() {
			return this.$route.name
		}
	},
	created() {
			this.getSiteSettingsServiceCall();
			this.getAllSportsCount();
		},
    mounted() {
      //for use on js pages
      window.store = this.$store;
      window.cookies = this.$cookies;
      window.router = this.$router;
      window.emitLogoutMsg = this.$refs.click_logout_msg
      window.authorization_token = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
      //for add on all APIs
      axios.defaults.headers.common['Authorization'] = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`;
      setHeaders();
      if (this.checkIsLogin()) {
        callFavouriteApis();
      }

      //checking internet connection
      window.addEventListener('online', this.updateOnlineStatus);
      window.addEventListener('offline', this.updateOnlineStatus);
      if (localStorage.getItem('default_class')) {
        document.documentElement.className = localStorage.getItem('default_class')
      }
      window.addEventListener('scroll', this.onScroll);
	  this.socketAllEvents()

	  if (this.checkIsLogin()){
			this.userData = this.$store?.getters?.stateUser;
			var balance = this.userData.balance
			this.LowBalanceModal = new Modal(document.getElementById('LowBalanceModal'));
			if(balance < 100){
				setInterval(() => {
					this.LowBalanceModal.show();
				}, 120000)
			}
		}
    },
    methods: {
		getGlobaLoader() {
			return this.$store.getters.globalLoader;
		},
		getSiteSettingsServiceCall() {
			this.$store.dispatch('setGlobalLoader', true);
			api.get(apiName.SITE_SETTINGS + "?domain=" + window.location.hostname).then(response => {
				this.$store.dispatch('setGlobalLoader', false);
				if (response && response.status == 200) {
					if (response?.data?.status === 0) {
						this.showErrorModalFunc(response.data.debug[0]);
					} else {
						let resData = response?.data?.data
						this.$store.dispatch('setSettings', resData);
						this.setManifest(resData);

						// Create FAVICON
						const favElement = document.createElement('link');
						favElement.rel = 'icon';
						favElement.href = resData.aws_url + resData.storage_path.domain_image + resData.fav_logo;
						document.head.insertAdjacentElement('beforeend', favElement);

						const title = document.getElementById("title");
						title.text = resData.domain_name;

						if (this.checkIsLogin())
							this.getWalletBalance();
					}
				}
			}).catch(error => {
				console.log("Site setting Error ")
				this.$store.dispatch('setGlobalLoader', false);
				if (error) {
					this.showErrorModalFunc(error[0]);
				}
			});
		},
		setManifest(resData) {
			let manifestName = resData.domain_name.charAt(0).toUpperCase() + resData.domain_name.slice(1).toLowerCase();
			var dynamicManifest = {
				name: manifestName,
				short_name: manifestName,
				start_url: window.location.origin,
				display: 'standalone',
				icons: [{
					src: resData.app_logo ? resData.aws_url + resData.storage_path.domain_image + resData.app_logo : resData.aws_url + resData.storage_path.domain_image + resData.fav_logo,
					sizes: "256x256 512x512 192x192 144x144",
					type: "image/png",
					purpose: 'any'
				}]
			}
			
			// Manifest Blob
			const stringManifest = JSON.stringify(dynamicManifest);
			const blob = new Blob([stringManifest], { type: 'application/json' });
			const manifestURL = URL.createObjectURL(blob);
			
			// Manifest Creation
			const favElement = document.createElement('link');
			favElement.rel = 'manifest';
			favElement.href = manifestURL;
			document.head.insertAdjacentElement('beforeend', favElement);
			
			// IOS APK Logo
			let logo = resData.aws_url + resData.storage_path.domain_image + resData.logo;
			document.querySelector('#appleIcon').setAttribute('href', logo);
			document.querySelector('#appleLaunchIcon').setAttribute('href', logo);

			// IOS App title
			const metaTag = document.createElement('meta');
			metaTag.setAttribute('name', 'apple-mobile-web-app-title');
			metaTag.setAttribute('content', manifestName);
			document.head.insertAdjacentElement('beforeend', metaTag);

		},
		getWalletBalance() {
			let authToken = this.$cookies.get(AUTH_TOKEN);
			if (!authToken) return;
			let headers = {
				'Authorization': `Bearer ${authToken}`
			}
			api.post(apiName.WALLET_BALANCE, {}, { headers }).then(response => {
				if (response && response.status == 200 && response.data) {
					let user = this.$store.getters.stateUser;
					user.balance = response.data.bl;
					user.exposure = response.data.ex;
					user.bets_count = response.data.bc;
					this.$store.dispatch('setUser', user);
					if ((user.exp - moment().utc().unix()) < 10) {
						this.refreshAuthToken(headers);
					} else {
						setTimeout(function () {
							this.getWalletBalance();
						}.bind(this), 2000);
					}
				} else {
					this.$cookies.keys().forEach(cookie => this.$cookies.remove(cookie))
					this.$store.dispatch('resetAllState');
				}
			}).catch(error => {
				console.log("wallet balance Error ")
				if (error) {
					this.showErrorModalFunc(error[0]);
				}
			});
		},
		refreshAuthToken(headers) {
			this.refreshInit = true;
			api.post(apiName.AUTH_REFRESH, {}, { headers }).then(response => {
				let user = this.$store.getters.stateUser;
				user.token = response.data.data.token;
				user.exp = response.data.data.exp;
				this.$cookies.set(AUTH_TOKEN, response.data.data.token);
				this.$store.dispatch('setToken', `Bearer ${response.data.data.token}`);
				window.authorization_token = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
				//for add on all APIs
				axios.defaults.headers.common['Authorization'] = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`;
				this.$store.dispatch('setUser', user);
				this.refreshInit = false;
				setTimeout(function () {
					this.getWalletBalance();
				}.bind(this), 2000);
			}).catch(error => {
				if (error) {
					this.showErrorModalFunc(error[0]);
				}
				this.refreshInit = false;
			});
		},
		getAllSportsCount(){
			this.loading = true;
            sportCount_api.get(apiName.SPORTS_COUNT).then(response => {
                this.loading = false;
                if (response && response.status == 200) {
					let resData =response?.data.data
                    console.log('sports count',resData)
					this.$store.dispatch('SetSportsCount',resData)
                }
            }).catch(error => {
                this.loading = false;
                if (error) {
                    console.log("Error : ", error);
                }
            });
		},
		checkForHeaderShow() {
			if (this.eventDetailHideSections && this.eventDetailHideSections.includes('show_full_screen') && this.routePath == 'sports-event-detail') {
				return false;
			} else {
				return true;
			}
		},
		updateOnlineStatus(e) {
			const {
				type
			} = e;
			this.onLine = type === 'online';
		},
		checkIsLogin() {
			return this.$store.getters.isAuthenticated;
		},
		showErrorModalFunc(message) {
			this.errorMsg = message;
			this.showErrorModal = true;
			setTimeout(() => {
				this.showErrorModal = false;
			}, 2000);
		},
		showSuccessModalFunc(message) {
			this.successMsg = message;
			this.showSuccessModal = true;
			setTimeout(() => {
				this.showSuccessModal = false;
			}, 2000);
		},
		goToTop() {
			window.scrollTo({
				top: 0,
				behavior: "smooth"
			});
		},
		onScroll() {
			if (this.scTimer) return;
			this.scTimer = setTimeout(() => {
				this.scY = window.scrollY;
				clearTimeout(this.scTimer);
				this.scTimer = 0;
			}, 100);
		},
		socketAllEvents(){
			if(this.$store.getters.stateUser){
				socketService.emit("sub",'depositRequest-'+this.$store.getters.stateUser.userid);					
				socketService.emit("sub",'logout-'+this.$store.getters.stateUser.userid);					
				socketService.emit("sub",'banned-'+this.$store.getters.stateUser.userid);

				socketService.on("DepositRequest", (data) => {
					if (data.type =="myId") {
						if (data.notify_type == 1) {
							this.$store.dispatch('setNotification');
							this.showSuccessModalFunc('New Notification...')
						}
					}
				});
				socketService.on("logout", (data) => {
					if (data.type == "logout") {
						// logout user from users
						// if (data.notify_type == 1) {
						// 	this.$store.dispatch('setNotification');
						// 	this.showSuccessModalFunc('New Notification...')
						// }
					}
				});	
				socketService.on("banned", (data) => {
					if (data.type == "match_ban") {
						// match ban condition
					}else if(data.type == 'league_ban'){
						// league ban condition
					}
				});
					
			}			
		}
	},
	watch: {
		onLine(v) {
			if (v) {
				this.showBackOnline = true;
				setTimeout(() => {
					this.showBackOnline = false;
				}, 1000);
			}
		}
	}
}
</script>

<style>
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
} */
</style>
