<template>
    <div v-if="gameItem" class="modal-content">
        <div class="modal-header">
            <div class="game-header-content">
                <div class="game-bak-close-block">
                    <a ref="close_button" data-bs-dismiss="modal"><img
                            src="@/assets/images/home-icon.webp" alt=""></a>
                    <strong class="ms-2">{{ gameItem?.game_name }}</strong>
                </div>
                <div class="close-game-btn">
                    <a data-bs-dismiss="modal"><img src="@/assets/images/red-close-btn.webp"
                            alt=""></a>
                </div>
            </div>
        </div>
        <div class="modal-body">
            <div v-if="!loading" class="game-iframe-sec">
                <div v-if="this.iFrameUrl" class="iframe-content">
                    <iframe :src="this.iFrameUrl" frameborder="0"
                        style="overflow:hidden;overflow-x:hidden;overflow-y:hidden;height:100%;width:100%;position:absolute;top:0px;left:0px;right:0px;bottom:0px"
                        height="100%" width="100%" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen
                        title="Tr&amp;eacute;ning TEXT"></iframe>
                </div>
            </div>
            <div v-else>
                <transition name="fade">
                    <div class="preloader d-flex align-items-center justify-content-center">
                        <div class="cssload-container">
                            <div class="cssload-loading"><i></i><i></i><i></i><i></i></div>
                        </div>
                    </div>
                </transition>
            </div>


        </div>
    </div>

    <!-- Game Play Modal End -->
</template>
<script>
import { getGameItem } from '@/shared/constants/shared-data';
import api from '../services/api';
import * as apiName from '../services/urls';
import { AUTH_TOKEN } from '@/shared/constants/cookies-const';

export default {
    name: 'IFrameModal',
    data() {
        return {
            loading: false,
            iFrameUrl: null,
            gameItem: null,
        }
    },
    mounted() {
        this.gameItem = this.getGameItemHere();
        console.log("Game Item : ",this.gameItem);
        if (this.gameItem.provider == 'poker') {
            this.getLivePockerUrl()
        }
        else {
            this.getDreamCasinoLoadGames()
        }
    },
    methods: {
        getLivePockerUrl() {
            this.loading = true;
            let headers = {
                'Authorization': `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
            }
            api.post(apiName.GET_LIVE_POCKER_URL + this.gameItem.game_code + '?dtype=m', {}, { headers }).then(response => {
                this.loading = false;
                if (response) {
                    if (response.status == 200) {
                        this.iFrameUrl = response?.data?.data?.url;
                        if (this.iFrameUrl == null || this.iFrameUrl == undefined || this.iFrameUrl == '') {
                            this.showErrorModalFunc('URL not found.')
                            this.closeModal();
                        }
                        //this.showSuccessModalFunc(response.data.message)
                        // this.closeModal();
                    }

                }
            }).catch(error => {
                this.loading = false;
                if (error) {
                    this.showErrorModalFunc(error[0]);
                    this.closeModal()
                }
            });
        },

        getDreamCasinoLoadGames() {
            this.loading = true;
            let headers = {
                'Authorization': `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
            }
            api.post(apiName.DREAM_CASINO + this.gameItem.game_code + '/' + this.gameItem.sm_id, {}, { headers }).then(response => {
                this.loading = false;
                if (response) {
                    if (response.status == 200) {
                        this.iFrameUrl = response?.data?.data?.url;
                        if (this.iFrameUrl == null || this.iFrameUrl == undefined || this.iFrameUrl == '') {
                            this.showErrorModalFunc('URL not found.')
                            this.closeModal();
                        }
                    }

                }
            }).catch(error => {
                this.loading = false;
                if (error) {
                    this.showErrorModalFunc(error[0]);
                    this.closeModal()
                }
            });
        },

        closeModal() {
            setTimeout(() => {
                this.$refs?.close_button.click();
            }, 400);
        },

        getGameItemHere() {
            return getGameItem();
        },
        showErrorModalFunc(message) {
            this.$emit('error-modal', message);
        },
        showSuccessModalFunc(message) {
            this.$emit('success-modal', message);
        },
    }
}
</script>