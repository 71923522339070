import * as apiName from './urls';
import api from './api';
import * as constants from '../constants/var-constants';

export function callFavouriteApis() {
    getGamesFavouriteServiceCall();
    getSportsFavouriteServiceCall();
}

export function getGamesFavouriteServiceCall()
{
    let data = {
        "game_type": 1
    }

    let headers ={
        authorization:window.authorization_token
    }

    api.post(apiName.GET_FAVOURITE_LIST, data,{headers}).then(response => {
        if (response) {
            if (response.status == 200) {
                console.log("response", response);
                if (response?.data?.status == 0) {
                }
                else {
                    var allData = response.data.data;
                    var ids = [];
                    for (var i = 0; i < allData.length; i++) {
                        ids.push(allData[i].match_id)
                    }
                    localStorage.setItem(constants.FAV_GAMES_LIST,JSON.stringify(ids));
                }
            }
        }
    }).catch(error => {
        if (error) {
            console.log("Error Login : ", error[0]);
        }
    });
}

export function getSportsFavouriteServiceCall()
{

    let data = {
        "game_type": 0
    }

    let headers ={
        authorization:window.authorization_token
    }

    api.post(apiName.GET_FAVOURITE_LIST, data,{headers}).then(response => {
        if (response) {
            if (response.status == 200) {
                console.log("response", response);
                if (response?.data?.status == 0) {
                }
                else {
                    var allData = response.data.data;
                    var ids = [];
                    for (var i = 0; i < allData.length; i++) {
                        ids.push(allData[i].match_id)
                    }
                    localStorage.setItem(constants.FAV_SPORTS_LIST,JSON.stringify(ids));
                }
            }
        }
    }).catch(error => {
        if (error) {
            console.log("Error Login : ", error[0]);
        }
    });
}