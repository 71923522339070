<template>
   <div class="container">
      <div class="flex-box footer-area" id="myDIV">
         <div class="sub-footer" @click="toggleMenuTab()">
            <button class="menu-btn menu-open off-can" :class="menuTabActive ? 'active':''" type="button" data-bs-toggle="offcanvas" data-bs-target="#sideMenu" aria-controls="sideMenu">
               <div class="menu-type">
                  <img src="@/assets/images/menuiconwhite.webp" alt="" class="live-1" />
                  <img src="@/assets/images/backarrowwhite.webp" alt="" class="live-2" />
               </div>
               <div v-if="menuTabActive" class="menu-opton">Back</div>
               <div v-else class="menu-opton">Menu</div>
            </button>
         </div>
         <div class="sub-footer">
            <a type="button" data-bs-target="#usernamemodal" data-bs-toggle="modal" @click="toggleChatModal()" ref="chatOpenCloaseBtn">
               <div class="menu-type"><img src="@/assets/images/supportIconWhite.webp" alt="" /></div>
               <div class="menu-opton">Support</div>
            </a>
         </div>
         <div class="sub-footer" @click="currentTab = 'home',$emit('close-sidebar')">
            <router-link to="/" class="menu-open" :class="{ active: $route.name == 'Home' }">
               <div class="menu-type"><img src="@/assets/images/homewhite.webp" alt="" /></div>
               <div class="menu-opton">Home</div>
            </router-link>
         </div>
         <div class="sub-footer" @click="currentTab = 'exchange'">
            <router-link to="/bet-exchange" class="menu-open" type="button" :class="{ active: $route.name == 'bet-exchange' }">
               <div class="menu-type"><img src="@/assets/images/exchwhite.webp" alt="" /></div>
               <div class="menu-opton">Exchange</div>
            </router-link>
         </div>
         <div class="sub-footer" @click=" toggleMybetsTab()">
            <button class="menu-btn menu-open off-can-2" :class="mybetsTabActive ? 'active':''" type="button" data-bs-toggle="offcanvas" data-bs-target="#mybetSideMenu" aria-controls="mybetSideMenu">
               <div class="menu-type">
                  <img src="@/assets/images/myBetsWhite.webp" alt="" class="live-1" />
                  <img src="@/assets/images/backarrowwhite.webp" alt="" class="live-2" />
               </div>
               <div v-if="mybetsTabActive" class="menu-opton">Back</div>
               <div v-else class="menu-opton">My Bets</div>
            </button>
         </div>
      </div>
      <!-- <SideMenu @close-side-nav="closeSideNav()"/> -->
      <!-- <MyBetSideMenu @close-side-nav="closeSideNav()"/> -->

      <div class="modal fade" id="usernamemodal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
        data-bs-keyboard="false" data-bs-backdrop="static">
        <div class="modal-dialog  thm-chat-modal-sec">
            <div class="modal-content">
                <iframe id="chat_modal" :src="iframeUrl" marginwidth="0" marginheight="0" frameborder="0" width="100%"
                    height="100%" scrolling="no" allowfullscreen="allowfullscreen" v-if="showChat"></iframe>
            </div>
        </div>
    </div>
   </div>
</template>

<script>
import api from '@/modules/chat/services/api';
import * as apiName from '@/modules/chat/services/urls';
import { AUTH_TOKEN } from '@/shared/constants/cookies-const';
export default {
   name:'Footer',
   components:{
      // SideMenu,
      // MyBetSideMenu
   },
   data(){
      return{
         currentTab: 'home',
         menuTabActive:false,
         mybetsTabActive:false,

         showChat: false,
         user_id: null,
         iframeUrl: null,
      }
   },
   computed: {
        userName() {
            return this.$store.getters.stateUser?.userid || null;
        },
        checkIsLogin() {
            return this.$store?.getters?.isAuthenticated;
        }
    },
    mounted() {
        const guestUserName = localStorage.getItem('guestUserName')
        if (guestUserName && !this.userName && !this.checkIsLogin) {
            this.user_id = guestUserName;
        } else {
            this.user_id = this.userName
        }
    },
   methods:{
      toggleMenuTab(){
         this.menuTabActive=!this.menuTabActive
      },
      toggleMybetsTab(){
         this.mybetsTabActive=!this.mybetsTabActive
      },
      async toggleChatModal() {
            const headers = {
                'Authorization': `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
            };

            const userid = this.user_id;
            const apiEndpoint = this.checkIsLogin ? apiName.GET_CHATBOT_IFRAME : apiName.GET_CHATBOT_IFRAME_GUEST;

            const requestData = userid && !this.checkIsLogin ? { userid } : {};

            api.post(apiEndpoint, requestData, { headers })
                .then(res => {
                    window.addEventListener('message', this.handleMessage);
                    this.iframeUrl = res.data.chat_bot_url;
                })
                .catch(error => {
                    this.showErrorModalFunc(error);
                });

            this.showChat = !this.showChat;
        },
        handleMessage(event) {
            if (event.data.userName) {
                this.user_id = event.data.userName;
                localStorage.setItem('guestUserName', this.user_id)
            }
            if (event.data === 'closeChatModal') {
                this.$refs.chatOpenCloaseBtn.click();
                window.removeEventListener('message', this.handleMessage);
            }
        },
        showErrorModalFunc(message) {
            this.$emit('error-modal', message);
        },
   }
}
</script>

<style>

</style>