<template>
    <button class="center-bottom-fix-btn" style="display: inline-block;" @click="goToTop" v-if="showButton">
       <img src="@/assets/images/up-arrow.webp" alt="">
      </button>
</template>

<script>
export default {
    name:'GotoTop',
  data() {
    return {
      showButton: false
    };
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      if (window.pageYOffset > 300) {
        this.showButton = true;
      } else {
        this.showButton = false;
      }
    },
    goToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth' // Smooth scrolling behavior
      });
    }
  }
};
</script>