<template>
    <section class="no-notification">
        <div class="container">
            <div class="no-notification-icon">
                <img src="@/assets/images/no-data-img.webp" alt="">
            </div>
            <div class="thm-heading">
                <h3 class="profit-color">No data found!</h3>
                <p>We can't find any item matching your search. </p>
            </div>
            <!-- <div class="thm-heading mt-lg-4 mt-2 data-btn">
                <a  class="thm-but ">Go back </a>
            </div> -->
        </div>
    </section>
</template>

<script>
export default {
    name: "NoDataFound"
}
</script>