const StatementModule = () => import(/* webpackChunkName: "profile-module" */ './views/Module.vue');
const MyBets = () => import(/* webpackChunkName: "profile" */ './views/MyBets.vue');
const ProfitLoss = () => import(/* webpackChunkName: "profile" */ './views/ProfitLoss.vue');
const AccountStatement = () => import(/* webpackChunkName: "profile" */ './views/AccountStatement.vue');

const ProfileRoutes = {
    path: '/',
    component: StatementModule,
    children: [
        {
            path: 'my-bets',
            name: 'my-bets',
            component: MyBets,
            meta: {
                title: 'My Bets',
              },
        },
        {
            path: 'profit-loss',
            name: 'profit-loss',
            component: ProfitLoss,
            meta: {
                title: 'Profit Loss',
              },
        },
        {
            path: 'account-statement',
            name: 'account-statement',
            component: AccountStatement,
            meta: {
                title: 'Account Statement',
              },
        }
    ],
}   

export default ProfileRoutes;