
export const SITE_SETTINGS='api/site-settings'
export const GET_SPORTS= 'v1/sports/management/getSport';
export const LOGOUT_API='api/logout';
export const AUTH_STATUS='api/auth-status';
export const CHANGE_STAKE_VALUES='api/change-stake-values';
export const GET_FAVOURITE_LIST='api/favorite-list';

export const USER_PROFILE='api/user-profile'
export const AUTH_API= '/api/auth-status'
export const WALLET_BALANCE='wallet';
export const AUTH_REFRESH='api/auth-refresh';
export const CAPTCHA_API='api/v1/captcha'
export const MATCHES_BY_SPORT_ID = 'v1/events/matches/';
export const SPORTS_COUNT='api/v1/events/count';
export const GET_MY_BETS='api/lotus/get-my-bets';
export const  OPEN_BETS_EVENT_LISTING='api/open-bets-event-listing';
