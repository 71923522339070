<template>
     <aside class="menu-footer">
         <div class="offcanvas offcanvas-start" tabindex="-1" id="sideMenu" aria-labelledby="sideMenuLabel">
            <div class="offcanvas-menu">
               <div class="menu-offcanvas-header">
                  <div class="search">
                     <input type="search" placeholder="Search events" />
                     <div class="search-img">
                        <img src="@/assets/images/magnifier-black.webp" alt="" />
                     </div>
                  </div>
               </div>
               <div class="menu-offcanvas-body">
                  <div class="bouns-sec">
                     <div class="bonus-now">
                        <div class="bonus-cum-list" data-bs-dismiss="offcanvas">
                           <router-link to="/favourites" @click="$emit('close-side-nav')">
                              <div class="bonus-icon">
                                 <div class="bonus-img">
                                    <img src="@/assets/images/star-sidebar1.webp" alt="" />
                                 </div>
                                 Favorites
                              </div>
                              <div class="bonus-btn">
                                 <div class="arrow-btn">
                                    <img src="@/assets/images/chevron-right-light.webp" alt="" />
                                 </div>
                              </div>
                           </router-link>
                        </div>
                        <div class="bonus-cum-list" data-bs-dismiss="offcanvas">
                           <router-link to="/bonus" @click="$emit('close-side-nav')">
                              <div class="bonus-icon">
                                 <div class="bonus-img">
                                    <img src="@/assets/images/bonus-sidebar-blue-new1.webp" alt="" />
                                 </div>
                                 Bonus
                              </div>
                              <div class="bonus-btn">
                                 <div class="arrow-btn">
                                    <img src="@/assets/images/chevron-right-light.webp" alt="" />
                                 </div>
                              </div>
                           </router-link>
                        </div>
                        <div class="bonus-cum-list" data-bs-dismiss="offcanvas">
                           <router-link to="/loyalty">
                              <div class="bonus-icon">
                                 <div class="bonus-img">
                                    <img src="@/assets/images/loyaltysidebarblue1.webp" alt="" />
                                 </div>
                                 Loyalty
                              </div>
                              <div class="bonus-btn">
                                 <div class="arrow-btn">
                                    <img src="@/assets/images/chevron-right-light.webp" alt="" />
                                 </div>
                              </div>
                           </router-link>
                        </div>
                        <div class="bonus-cum-list" data-bs-dismiss="offcanvas">
                           <router-link to="/refer-earn" @click="$emit('close-side-nav')">
                              <div class="bonus-icon">
                                 <div class="bonus-img">
                                    <img src="@/assets/images/deposit-icon.webp" alt="" />
                                 </div>
                                 Refer and Earn
                              </div>
                              <div class="bonus-btn">
                                 <div class="arrow-btn">
                                    <img src="@/assets/images/chevron-right-light.webp" alt="" />
                                 </div>
                              </div>
                           </router-link>
                        </div>
                     </div>
                     <div class="Others sports-game">
                        <div class="collapse-btn">
                           <h2 class="sports-name">sports</h2>
                           <div class="collapse-bar" v-for="(sport,n_index) in non_custom_sports" :key="n_index">
                              <div class="accordion accordion-flush" id="accordionFlushExample">
                                 <div class="accordion-item">
                                    <h2 class="accordion-header" id="flush-headingOnes">
                                       <button class="accordion-button flex-btn collapsed" @click="getLeagues(sport)" type="button" data-bs-toggle="collapse" :data-bs-target="'#flush-collapsethreeon' + n_index" aria-expanded="false" :aria-controls="'flush-collapsethreeon' + n_index">
                                          <div class="accordion-div">
                                             <div class="bonus-cum-list flex-d" >
                                                <div class="bonus-icon">
                                                   <div class="bonus-img">
                                                      <img v-if="sport.id==4"  src="@/assets/images/cricket-sidebar.webp" alt="" />
                                                      <img v-if="sport.id==1"  src="@/assets/images/football-sidebar.webp" alt="" />
                                                      <img v-if="sport.id==2"  src="@/assets/images/tennis-sidebar.webp" alt="" />
                                                   </div>
                                                   {{ capitalizeFirstLetter(sport.name)}}
                                                </div>
                                             </div>
                                          </div>
                                          <div class="flex-box w-cusd">
                                             <div class="content-btn">{{sportsCount?.[sport.id]?.total}}</div>
                                             <div class="arrow-img"><img src="@/assets/images/chevron-right-light.webp" alt="" /></div>
                                          </div>
                                       </button>
                                    </h2>
                                    <div :id="'flush-collapsethreeon' + n_index" class="accordion-collapse collapse" aria-labelledby="flush-headingOnes" data-bs-parent="#accordionFlushExample">
                                       <div class="collapse-bar">
                                          <div class="accordion accordion-flush" id="accordionFlushExample">
                                             <div class="accordion-item" v-for="(league,l_index) in sport.leagueList" :key="l_index">
                                                <h2 class="accordion-header" id="flush-headingOnes">
                                                   <button
                                                      class="accordion-button flex-btn collapsed"
                                                      type="button"
                                                      data-bs-toggle="collapse"
                                                      :data-bs-target="'#flush-collapsethreeonadd'+league[0]?.matchId"
                                                      aria-expanded="false"
                                                      aria-controls="flush-collapsethree"
                                                      @click="getMatches(league)"
                                                   >
                                                      <div class="accordion-div">
                                                         <div class="bonus-cum-list flex-d">
                                                            <div class="bonus-icon ellipese-word">
                                                               <div class="bonus-img">
                                                                  <img src="@/assets/images/trophy-sidebar-red.webp" alt="" />
                                                               </div>
                                                              {{l_index}}
                                                            </div>
                                                         </div>
                                                      </div>
                                                      <div class="flex-box w-cusd">
                                                         <div class="content-btn">{{ league?.length }}</div>
                                                         <div class="arrow-img"><img src="@/assets/images/chevron-right-light.webp" alt="" /></div>
                                                      </div>
                                                   </button>
                                                </h2>
                                                <div :id="'flush-collapsethreeonadd'+ league[0]?.matchId" class="accordion-collapse collapse" aria-labelledby="flush-headingOnes" data-bs-parent="#accordionFlushExample">
                                                   <div class="accordion-body">
                                                      <div class="show-and-hide hidden-menu">
                                                         <div class="bonus-cum-list" v-for="(match, m_index) in matchesList" :key="m_index">
                                                            <a @click="clickOnSportItem(match)" data-bs-dismiss="offcanvas">
                                                               <div class="bonus-icon ellipese-word">
                                                                  <div class="bonus-img dry-cic">
                                                                     <img src="@/assets/images/dry-clean.webp" alt="" />
                                                                  </div>
                                                                  {{ match.event_name }}
                                                               </div>
                                                               <div class="bonus-btn">
                                                                  <div class="arrow-btn">
                                                                     <img src="@/assets/images/chevron-right-light.webp" alt="" />
                                                                  </div>
                                                               </div>
                                                            </a>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div class="bonus-now">
                              <div class="bonus-cum-list" data-bs-dismiss="offcanvas">
                                 <router-link :to="{ name: 'racing-category', params: { type: 'HORSE_RACE' } }">
                                    <div class="bonus-icon">
                                       <div class="bonus-img">
                                          <img src="@/assets/images/menu-7.webp" alt="" />
                                       </div>
                                       Horse Racing
                                    </div>
                                    <div class="bonus-btn">
                                       <div class="arrow-btn">
                                          <img src="@/assets/images/chevron-right-light.webp" alt="" />
                                       </div>
                                    </div>
                                 </router-link>
                              </div>
                              <div class="bonus-now" data-bs-dismiss="offcanvas">
                                 <div class="bonus-cum-list">
                                    <router-link :to="{ name: 'racing-category', params: { type: 'GREY_HOUND_RACE' } }">
                                       <div class="bonus-icon">
                                          <div class="bonus-img">
                                             <img src="@/assets/images/menu-4339.webp" alt="" />
                                          </div>
                                          Greyhound Racing
                                       </div>
                                       <div class="bonus-btn">
                                          <div class="arrow-btn">
                                             <img src="@/assets/images/chevron-right-light.webp" alt="" />
                                          </div>
                                       </div>
                                    </router-link>
                                 </div>
                                 <div class="bonus-now">
                                    <div class="bonus-cum-list" data-bs-dismiss="offcanvas">
                                       <router-link to="/matka">
                                          <div class="bonus-icon">
                                             <div class="bonus-img">
                                                <img src="@/assets/images/menu-2378961.webp" alt="" />
                                             </div>
                                             Matka
                                          </div>
                                          <div class="bonus-btn">
                                             <div class="arrow-btn">
                                                <img src="@/assets/images/chevron-right-light.webp" alt="" />
                                             </div>
                                          </div>
                                       </router-link>
                                    </div>
                                    <div class="bonus-now" data-bs-dismiss="offcanvas">
                                       <div class="bonus-cum-list">
                                          <a href="Javascript:void(0);" @click="goToSportsBook()">
                                             <div class="bonus-icon">
                                                <div class="bonus-img">
                                                   <img src="@/assets/images/BtiIcon.webp" alt="" />
                                                </div>
                                                Sportsbook
                                             </div>
                                             <div class="bonus-btn">
                                                <div class="arrow-btn">
                                                   <img src="@/assets/images/chevron-right-light.webp" alt="" />
                                                </div>
                                             </div>
                                          </a>
                                       </div>
                                    </div>
                                    <div class="bonus-now" data-bs-dismiss="offcanvas">
                                       <div class="bonus-cum-list">
                                          <router-link to="/cricket-fight">
                                             <div class="bonus-icon">
                                                <div class="bonus-img c-fight ">
                                                   <img src="@/assets/images/cricket-fight.webp" alt="" />
                                                </div>
                                                Cricket Fight
                                             </div>
                                             <div class="bonus-btn">
                                                <div class="arrow-btn">
                                                   <img src="@/assets/images/chevron-right-light.webp" alt="" />
                                                </div>
                                             </div>
                                          </router-link>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div class="collapse-bar" v-for="(sport,c_index) in custom_sports" :key="c_index">
                                 <div class="accordion accordion-flush" id="accordionFlushExample">
                                    <div class="accordion-item">
                                       <h2 class="accordion-header" id="flush-headingOnes">
                                          <button class="accordion-button flex-btn collapsed" @click="getLeagues(sport)" type="button" data-bs-toggle="collapse" :data-bs-target="'#flush-collapsecustom' + c_index" aria-expanded="false" :aria-controls="'flush-collapsecustom' + c_index">
                                             <div class="accordion-div">
                                                <div class="bonus-cum-list flex-d" >
                                                   <div class="bonus-icon">
                                                      <div class="bonus-img">
                                                         <img :src="sport.sport_icon" alt="" />
                                                      </div>
                                                      {{sport.name}}
                                                   </div>
                                                </div>
                                             </div>
                                             <div class="flex-box w-cusd">
                                                <div class="content-btn">{{sportsCount?.[sport.id]?.total}}</div>
                                                <div class="arrow-img"><img src="@/assets/images/chevron-right-light.webp" alt="" /></div>
                                             </div>
                                          </button>
                                       </h2>
                                       <div :id="'flush-collapsecustom' + c_index" class="accordion-collapse collapse" aria-labelledby="flush-headingOnes" data-bs-parent="#accordionFlushExample">
                                          <div class="collapse-bar">
                                             <div class="accordion accordion-flush" id="accordionFlushExample">
                                                <div class="accordion-item" v-for="(league,l_index) in sport.leagueList" :key="l_index">
                                                   <h2 class="accordion-header" id="flush-headingOnes">
                                                      <button
                                                         class="accordion-button flex-btn collapsed"
                                                         type="button"
                                                         data-bs-toggle="collapse"
                                                         :data-bs-target="'#flush-collapsecustom'+league[0]?.matchId"
                                                         aria-expanded="false"
                                                         aria-controls="flush-collapsethree"
                                                         @click="getMatches(league)"
                                                      >
                                                         <div class="accordion-div">
                                                            <div class="bonus-cum-list flex-d">
                                                               <div class="bonus-icon ellipese-word">
                                                                  <div class="bonus-img">
                                                                     <img src="@/assets/images/trophy-sidebar-red.webp" alt="" />
                                                                  </div>
                                                               {{l_index}}
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div class="flex-box w-cusd">
                                                            <div class="content-btn">{{ league?.length }}</div>
                                                            <div class="arrow-img"><img src="@/assets/images/chevron-right-light.webp" alt="" /></div>
                                                         </div>
                                                      </button>
                                                   </h2>
                                                   <div :id="'flush-collapsecustom'+ league[0]?.matchId" class="accordion-collapse collapse" aria-labelledby="flush-headingOnes" data-bs-parent="#accordionFlushExample">
                                                      <div class="accordion-body">
                                                         <div class="show-and-hide hidden-menu">
                                                            <div class="bonus-cum-list" v-for="(match, m_index) in matchesList" :key="m_index">
                                                               <a @click="clickOnSportItem(match)" data-bs-dismiss="offcanvas">
                                                                  <div class="bonus-icon ellipese-word">
                                                                     <div class="bonus-img dry-cic">
                                                                        <img src="@/assets/images/dry-clean.webp" alt="" />
                                                                     </div>
                                                                     {{ match.event_name }}
                                                                  </div>
                                                                  <div class="bonus-btn">
                                                                     <div class="arrow-btn">
                                                                        <img src="@/assets/images/chevron-right-light.webp" alt="" />
                                                                     </div>
                                                                  </div>
                                                               </a>
                                                            </div>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>

                              <section class="Casino">
                                 <h2 class="sports-name">Casino</h2>
                                 <div class="bonus-now">
                                    <div class="bonus-cum-list" data-bs-dismiss="offcanvas">
                                       <router-link to="/games/popular-games">
                                          <div class="bonus-icon">
                                             <div class="bonus-img">
                                                <img src="@/assets/images/bingo-sidebar.webp" alt="" />
                                             </div>
                                             Popular Games
                                          </div>
                                          <div class="bonus-btn">
                                             <div class="arrow-btn">
                                                <img src="@/assets/images/chevron-right-light.webp" alt="" />
                                             </div>
                                          </div>
                                       </router-link>
                                    </div>
                                    <div class="bonus-cum-list" data-bs-dismiss="offcanvas">
                                       <router-link to="/games/live-casino">
                                          <div class="bonus-icon">
                                             <div class="bonus-img">
                                                <img src="@/assets/images/livecasinoicon.webp" alt="" />
                                             </div>
                                             Live Casino
                                          </div>
                                          <div class="bonus-btn">
                                             <div class="arrow-btn">
                                                <img src="@/assets/images/chevron-right-light.webp" alt="" />
                                             </div>
                                          </div>
                                       </router-link>
                                    </div>
                                    <div class="bonus-cum-list" data-bs-dismiss="offcanvas">
                                       <router-link to="/games/slot-games">
                                          <div class="bonus-icon">
                                             <div class="bonus-img">
                                                <img src="@/assets/images/slot-game-sidebar.webp" alt="" />
                                             </div>
                                             Slot Games & Bingo
                                          </div>
                                          <div class="bonus-btn">
                                             <div class="arrow-btn">
                                                <img src="@/assets/images/chevron-right-light.webp" alt="" />
                                             </div>
                                          </div>
                                       </router-link>
                                    </div>
                                 </div>
                              </section>
                              <section class="Virtual-Sports">
                                 <h2 class="sports-name">Virtual Sports</h2>
                                 <div class="bonus-now">
                                    <div class="bonus-cum-list">
                                       <a>
                                          <div class="bonus-icon">
                                             <div class="bonus-img">
                                                <img src="@/assets/images/sidebar-cricket-vsp.webp" alt="" />
                                             </div>
                                             Cricket
                                          </div>
                                          <div class="bonus-btn">
                                             <div class="arrow-btn">
                                                <img src="@/assets/images/chevron-right-light.webp" alt="" />
                                             </div>
                                          </div>
                                       </a>
                                    </div>
                                 </div>
                              </section>
                              <section class="Supernowa">
                                 <h2 class="sports-name">Royal Gamimg</h2>
                                 <div class="bonus-now">
                                    <div class="bonus-cum-list" data-bs-dismiss="offcanvas">
                                       <router-link to="/games/royal-games">
                                          <div class="bonus-icon">
                                             <div class="bonus-img">
                                                <img src="@/assets/images/supernowa_provider_icon.webp" alt="" />
                                             </div>
                                             Royal Gamimg
                                          </div>
                                          <div class="bonus-btn">
                                             <div class="arrow-btn">
                                                <img src="@/assets/images/chevron-right-light.webp" alt="" />
                                             </div>
                                          </div>
                                       </router-link>
                                    </div>
                                 </div>
                              </section>
                              <section class="Others">
                                 <div class="collapse-btn">
                                    <h2 class="sports-name">Others</h2>
                                    <div class="collapse-bar">
                                       <div class="accordion accordion-flush" id="accordionFlushExample">
                                          <div class="accordion-item">
                                             <h2 class="accordion-header" id="flush-headingOnes">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsethree" aria-expanded="false" aria-controls="flush-collapsethree">
                                                   <div class="accordion-div">
                                                      <div class="bonus-cum-list flex-d">
                                                         <div class="bonus-icon">
                                                            <div class="bonus-img">
                                                               <img src="@/assets/images/policy-client-client.webp" alt="" />
                                                            </div>
                                                            Terms and Policy
                                                         </div>
                                                         <div class="bonus-btn ab-us">
                                                            <div class="arrow-btn">
                                                               <img src="@/assets/images/chevron-right-light.webp" alt="" />
                                                            </div>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </button>
                                             </h2>
                                             <div id="flush-collapsethree" class="accordion-collapse collapse" aria-labelledby="flush-headingOnes" data-bs-parent="#accordionFlushExample">
                                                <div class="accordion-body">
                                                   <div class="show-and-hide hidden-menu">
                                                      <div class="bonus-cum-list" data-bs-dismiss="offcanvas">
                                                         <router-link to="/privacy-policy">
                                                            <div class="bonus-icon">
                                                               <div class="bonus-img">
                                                                  <img src="@/assets/images/policy-list-client.webp" alt="" />
                                                               </div>
                                                               Privacy Policy
                                                            </div>
                                                            <div class="bonus-btn">
                                                               <div class="arrow-btn">
                                                                  <img src="@/assets/images/chevron-right-light.webp" alt="" />
                                                               </div>
                                                            </div>
                                                         </router-link>
                                                      </div>
                                                      <div class="bonus-cum-list" data-bs-dismiss="offcanvas">
                                                         <router-link to="/responsible-gaming">
                                                            <div class="bonus-icon">
                                                               <div class="bonus-img">
                                                                  <img src="@/assets/images/policy-list-client.webp" alt="" />
                                                               </div>
                                                               Responsible Gambling
                                                            </div>
                                                            <div class="bonus-btn">
                                                               <div class="arrow-btn">
                                                                  <img src="@/assets/images/chevron-right-light.webp" alt="" />
                                                               </div>
                                                            </div>
                                                         </router-link>
                                                      </div>
                                                      <div class="bonus-cum-list" data-bs-dismiss="offcanvas">
                                                         <router-link to="/terms-conditions">
                                                            <div class="bonus-icon">
                                                               <div class="bonus-img">
                                                                  <img src="@/assets/images/policy-list-client.webp" alt="" />
                                                               </div>
                                                               Terms and Conditions
                                                            </div>
                                                            <div class="bonus-btn">
                                                               <div class="arrow-btn">
                                                                  <img src="@/assets/images/chevron-right-light.webp" alt="" />
                                                               </div>
                                                            </div>
                                                         </router-link>
                                                      </div>
                                                      <div class="bonus-cum-list" data-bs-dismiss="offcanvas">
                                                         <router-link to="/about-us">
                                                            <div class="bonus-icon">
                                                               <div class="bonus-img">
                                                                  <img src="@/assets/images/policy-list-client.webp" alt="" />
                                                               </div>
                                                               About Us
                                                            </div>
                                                            <div class="bonus-btn">
                                                               <div class="arrow-btn">
                                                                  <img src="@/assets/images/chevron-right-light.webp" alt="" />
                                                               </div>
                                                            </div>
                                                         </router-link>
                                                      </div>
                                                      <div class="bonus-cum-list" data-bs-dismiss="offcanvas">
                                                         <router-link to="/faq">
                                                            <div class="bonus-icon">
                                                               <div class="bonus-img">
                                                                  <img src="@/assets/images/policy-list-client.webp" alt="" />
                                                               </div>
                                                               FAQ
                                                            </div>
                                                            <div class="bonus-btn">
                                                               <div class="arrow-btn">
                                                                  <img src="@/assets/images/chevron-right-light.webp" alt="" />
                                                               </div>
                                                            </div>
                                                         </router-link>
                                                      </div>
                                                      <div class="bonus-cum-list" data-bs-dismiss="offcanvas">
                                                         <router-link to="/kyc-policy">
                                                            <div class="bonus-icon">
                                                               <div class="bonus-img">
                                                                  <img src="@/assets/images/policy-list-client.webp" alt="" />
                                                               </div>
                                                               KYC Policy
                                                            </div>
                                                            <div class="bonus-btn">
                                                               <div class="arrow-btn">
                                                                  <img src="@/assets/images/chevron-right-light.webp" alt="" />
                                                               </div>
                                                            </div>
                                                         </router-link>
                                                      </div>
                                                      <div class="bonus-cum-list" data-bs-dismiss="offcanvas">
                                                         <router-link to="/aml-policy">
                                                            <div class="bonus-icon">
                                                               <div class="bonus-img">
                                                                  <img src="@/assets/images/policy-list-client.webp" alt="" />
                                                               </div>
                                                               AML Policy
                                                            </div>
                                                            <div class="bonus-btn">
                                                               <div class="arrow-btn">
                                                                  <img src="@/assets/images/chevron-right-light.webp" alt="" />
                                                               </div>
                                                            </div>
                                                         </router-link>
                                                      </div>
                                                      <div class="bonus-cum-list" data-bs-dismiss="offcanvas">
                                                         <router-link to="/self-exculusion-policy">
                                                            <div class="bonus-icon">
                                                               <div class="bonus-img">
                                                                  <img src="@/assets/images/policy-list-client.webp" alt="" />
                                                               </div>
                                                               Self Exclusion Policy
                                                            </div>
                                                            <div class="bonus-btn">
                                                               <div class="arrow-btn">
                                                                  <img src="@/assets/images/chevron-right-light.webp" alt="" />
                                                               </div>
                                                            </div>
                                                         </router-link>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div class="collapse-bar">
                                       <div class="accordion accordion-flush" id="accordionFlushExample">
                                          <div class="accordion-item">
                                             <h2 class="accordion-header" id="flush-headingOnes">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefour" aria-expanded="false" aria-controls="flush-collapsefour">
                                                   <div class="accordion-div">
                                                      <div class="bonus-cum-list flex-d" href="#language_selection_pop_up" data-bs-toggle="modal">
                                                         <div class="bonus-icon">
                                                            <div class="bonus-img">
                                                               <img src="@/assets/images/language_setting_icon-client.webp" alt="" />
                                                            </div>
                                                            Language Settings
                                                         </div>
                                                         <div class="bonus-btn ab-us">
                                                            <div class="arrow-btn">
                                                               <img src="@/assets/images/chevron-right-light.webp" alt="" />
                                                            </div>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </button>
                                             </h2>
                                             <!-- <div id="flush-collapsefour" class="accordion-collapse collapse" aria-labelledby="flush-headingOnes" data-bs-parent="#accordionFlushExample">
                                                <div class="accordion-body">
                                                   <div class="show-and-hide hidden-menu">
                                                      <div class="bonus-cum-list flex-d b-bottom">
                                                         <div class="bonus-icon">
                                                            <div class="bonus-img">
                                                               <img src="@/assets/images/india-flag-client.webp" alt="" />
                                                            </div>
                                                            Hindi
                                                         </div>
                                                         <div class="bonus-btn">
                                                            <div class="arrow-btn">
                                                               <img src="@/assets/images/chevron-right-light.webp" alt="" />
                                                            </div>
                                                         </div>
                                                      </div>
                                                      <div class="bonus-cum-list flex-d b-bottom">
                                                         <div class="bonus-icon">
                                                            <div class="bonus-img">
                                                               <img src="@/assets/images/language-flag-client.webp" alt="" />
                                                            </div>
                                                            English
                                                         </div>
                                                         <div class="bonus-btn">
                                                            <div class="arrow-btn">
                                                               <img src="@/assets/images/chevron-right-light.webp" alt="" />
                                                            </div>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div> -->
                                          </div>
                                       </div>
                                    </div>
                                 </div>

                                 <div class="bonus-now">
                                    <div class="bonus-cum-list">
                                       <a href="/#" download>
                                          <div class="bonus-icon">
                                             <div class="bonus-img">
                                                <img src="@/assets/images/download-app.webp" alt="" />
                                             </div>
                                             Download Android App
                                          </div>
                                          <div class="bonus-btn">
                                             <div class="arrow-btn">
                                                <img src="@/assets/images/chevron-right-light.webp" alt="" />
                                             </div>
                                          </div>
                                       </a>
                                    </div>
                                 </div>
                              </section>
                              <div class="all-img-bg">
                                 <div class="Sponsors">
                                    <div class="Principal">
                                       <div class="Principal-inner">Principal Sponsors</div>
                                       <img src="@/assets/images/knightRidersSponcerLogo.webp" />
                                    </div>
                                    <div class="v-border"></div>
                                    <div class="Principal winner">
                                       <div class="Principal-inner">Awards And Recognition</div>
                                       <img src="@/assets/images/awardWinnerLogoFooter.webp" />
                                    </div>
                                 </div>
                                 <div class="gc"><img src="@/assets/images/Gaming-Curacao-Logo.webp" alt="Gaming" /></div>
                                 <div class="slide-partner">
                                    <div class="Gaming-Partners-heading">Gaming Partners</div>
                                    <div class="slide-icon">
                                       <img src="@/assets/images/SwipeIcon.webp" alt="" />
                                    </div>
                                    <div class="Gaming-Partners-wrapper">
                                       <div class="slide-partner-banner">
                                          <div class="slide-partner-banner-img">
                                             <div class="partner-banner"><img src="@/assets/images/ezugiLogoNew.webp" alt="ezugiLogoNew" /></div>
                                             <div class="partner-banner"><img src="@/assets/images/aGLogoNew.webp" alt="aGLogoNew" /></div>
                                             <div class="partner-banner"><img src="@/assets/images/betGamesLogoNew.webp" alt="betGamesLogoNew" /></div>
                                             <div class="partner-banner"><img src="@/assets/images/evolutionGamesNew.webp" alt="evolutionGamesNew" /></div>
                                             <div class="partner-banner"><img src="@/assets/images/netentLogoNew.webp" alt="netentLogoNew" /></div>
                                             <div class="partner-banner"><img src="@/assets/images/tvbetLogoNew.webp" alt="tvbetLogoNew" /></div>
                                             <div class="partner-banner"><img src="@/assets/images/superSpadeLogoNew.webp" alt="superSpadeLogoNew" /></div>
                                             <div class="partner-banner"><img src="@/assets/images/supernowaLogoNew.webp" alt="supernowaLogoNew" /></div>
                                             <div class="partner-banner"><img src="@/assets/images/sexygaminglogo.webp" alt="sexygaming_logo_footer" /></div>
                                             <div class="partner-banner"><img src="@/assets/images/BTILogoNew.webp" alt="BTILogoNew" /></div>
                                             <div class="partner-banner"><img src="@/assets/images/7mojosIcon.webp" alt="7mojosIcon" /></div>
                                             <div class="partner-banner"><img src="@/assets/images/spribe_logo_footer.webp" alt="spribe_logo_footer" /></div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 
                                 <template v-if="siteSettings?.business_type==2">
                                    <div class="Gaming-Partners-wrapper justify-content-center">
                                       <div class="Gaming-Partners-heading">Payment Methods</div>
                                       <div class="payment-method">
                                          <img src="@/assets/images/upiLogoNew.webp" alt="upiLogoNew" />
                                       </div>
                                       <div class="payment-method">
                                          <img src="@/assets/images/gpayLogoNew.webp" alt="gpayLogoNew" />
                                       </div>
                                       <div class="payment-method">
                                          <img src="@/assets/images/paytmLogoNew.webp" alt="paytmLogoNew" />
                                       </div>
                                    </div>
                                    <div class="Gaming-Partners-wrapper justify-content-center">
                                       <a href="https://www.gamstop.co.uk/" class="payment-method w-auto-s">
                                          <img src="@/assets/images/gamestopLogo.webp" alt="gamestopLogo" />
                                       </a>
                                       <a href="https://www.begambleaware.org/" class="payment-method w-auto-s">
                                          <img src="@/assets/images/beAwareLogo.webp" alt="beAwareLogo" />
                                       </a>
                                       <a href="https://www.gamcare.org.uk/" class="payment-method w-auto-s">
                                          <img src="@/assets/images/gamcareLogo.webp" alt="gamcareLogo" />
                                       </a>
                                    </div>
                                 </template>

                                 <div class="plus-age">
                                    <div class="au-img">
                                       <img src="@/assets/images/18-plus-color-footer.webp" alt="" />
                                    </div>
                                    © 2021-2023
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </aside>
      <LanguageSelection />
</template>
<script>

import LanguageSelection from '@/shared/components/header/LanguageSelection.vue';
import { mapGetters } from 'vuex';
import click_api from '../../services/click_api';
import * as apiName from '../../services/urls';
export default {
    name: 'SideMenu',
    components:{
      LanguageSelection
    },
    emits:['close-side-nav'],
    data(){
        return{
            custom_sports: JSON.parse(localStorage.getItem('sports_List')) ? JSON.parse(localStorage.getItem('sports_List')).custom : [],
            non_custom_sports: JSON.parse(localStorage.getItem('sports_List')) ? JSON.parse(localStorage.getItem('sports_List')).non_custom : [],
            sportsData:[],
            leagues_list:{},
            matchesList:[],
        }
    },
    computed: {
		...mapGetters({
			sportsCount: 'sportsCount',
		}),
      siteSettings() {
            return this.$store.getters.siteSettings
        }
	},
    methods:{
        getLeagues(sport){
            if (sport.leagueList) {
                return;
            }
            this.loading = true;
            click_api.get(apiName.MATCHES_BY_SPORT_ID + sport.id).then(response => {
                this.loading = false;
                if (response) {
                    if (response.status == 200) {
                        console.log("matches by id", response);
                        this.sportsData=response.data.data
                        sport.leagueList = {};

                        this.sportsData?.forEach(item => {
                            const leagueName = item.league_name;
                            if (!sport.leagueList[leagueName]) {
                                sport.leagueList[leagueName]=[]
                            }
                            sport.leagueList[leagueName].push(item)
                        });

                        console.log('leagues', sport)

                    }
                }
            }).catch(error => {
                this.loading = false;
                if (error) {
                    console.log("Error : ", error);
                    this.showErrorModalFunc(error.data.message);
                }
            });
        },
        goToSportsBook() {
            let isLogin = this.$store.getters.isAuthenticated;
            if(isLogin) {
                this.$router.push({ name: 'sports-book' })
            } else {
                this.showErrorModalFunc('Please Login to access Sportsbook !!');
            }
        },
        showErrorModalFunc(message) {
            this.$emit('error-modal', message);
        },
        getMatches(matches){
            this.matchesList = matches
        },
        clickOnSportItem(match){
            this.$router.push('/sports-event-detail/' + match.event_id )
        },
        capitalizeFirstLetter(str) {
         if (!str) return '';
            return str.charAt(0).toUpperCase() + str.slice(1);
         }
   }
}
</script>

<style scoped>
input[type="search"]::placeholder{
   font-style: italic;
}
</style>