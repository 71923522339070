<template>
  <div>
    <section class="login-form p-bottom" loading="lazy">
         <div class="container">
            <div class="featured-box-login">
                <div class="top-heading flex-box reg">
                <img src="@/assets/images/lakr.webp" alt="" class="aw-img">
                <div class="bg-log"> <h1>signup</h1></div>
                <img src="@/assets/images/etaward.webp" alt="" class="aw-b-img">
                </div>

                <p class="create pt-5">
                    Get your ready-made ID from whatsapp
                </p>
                <form class="form-control-head">
                    <div class="whats-app" v-if="siteSettings && siteSettings?.business_type == 2">
                        <div class="button-whatsapp">
                            <a class="btn-whatsapp" :href="'//wa.me/' + siteSettings?.social_link.whatsappno1" target="_blank" 
                                v-if="siteSettings && siteSettings?.social_link && siteSettings?.social_link.whatsappno1">
                                <img src="@/assets/images/whatsapp.webp" alt="" /> 
                                <span class="blinking">Whatsapp Now</span>
                            </a>
                        </div>
                        <h3 class="whats-with"><span class="whats-with-now">OR create account with Mobile Number</span></h3>
                    </div>
                    <div class="get-mob">
                        <div class="flex-box-input w-100">
                            <div class="flex-box-input-lef w-20">
                                <select v-model="form.code" class="form-select" id="autoSizingSelect">
                                    <option :value="allowedCountry.phonecode"
                                       v-for="(allowedCountry, countryIndex) in siteSettings?.country_code_list"
                                       :key="countryIndex">+{{ allowedCountry.phonecode }}</option>
                                </select>
                                </div>
                            <div class="flex-box-input-center w-60">
                                <input type="tel" v-model="form.mobileNo" maxlength="10" class="form-control add" id="autoSizingInput" placeholder="Mobile Number" />
                                </div>
                        
                            <div class="flex-box-input-rig w-20" v-if="loadingOtp || timerRunning || form.mobileNo.length != 10">
                                <button class="btn cmn-bdr-btn otp-btn" @click.prevent>
                                    OTP
                                    <img src="@/assets/images/arrow-right-circle.webp" alt="">
                                </button>
                            </div>
                            <div class="flex-box-input-rig w-20 otp-btn-active" v-else >
                                <button class="btn cmn-bdr-btn otp-btn active" @click.prevent="sendOtpCall()">
                                    {{ resendOtp ? 'RESEND OTP' : 'OTP'}}
                                    <img src="@/assets/images/arrow-right-circle.webp" alt="">
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="warning" v-if="showErrorMobile && form.mobileNo == ''">
                        Please enter Phone Number
                    </div>
            
                    <span id="resend_time" class="otp-countdown-text text-danger" v-if="timerRunning">
                        Resend in : &nbsp;<span id="timer">{{ formatTime(minutes) + ':' + formatTime(seconds) }}</span>
                    </span>
                    <!-- <span id="resend_button" class="otp-countdown-text" >
                        <button @click.prevent="sendOtpCall()" v-if="resendOtp" :disabled="loadingOtp || timerRunning" class="text-success resend-btn float-end" type="button">
                            RESEND OTP
                        </button>
                    </span> -->

                    <div class="get-mob for" v-if="isOtpApiCalled">
                        <input type="number" v-model="form.otp" class="form-control" id="inputPassword2" placeholder="Enter the OTP" />
                    </div>

                    <div class="warning" v-if="showValidationError && getOtp().length < 6">
                        Please enter OTP
                    </div>

                    <div class="get-mob for">
                        <input type="text" v-model="form.userName" class="form-control" id="inputPassword2" placeholder="Username" />
                    </div>
                    <div class="warning" v-if="showValidationError && form.userName == ''">
                        Please enter Username
                    </div>

                    <div class="get-mob for">
                        <input v-if="showPassword" v-model="form.password" type="text" class="form-control" id="inputPassword2" placeholder="Pasword*" />
                        <input v-else type="password" v-model="form.password" class="form-control" id="inputPassword2" placeholder="Pasword*" />
                        <div class="rug-img-input">
                            <button class="paasword-eye show-pasword" @click.prevent="showPassword =!showPassword">
                                <img v-if="showPassword" src="@/assets/images/hide-pasword.webp" alt="show-pass" />
                                <img v-else src="@/assets/images/show-pasword.webp" alt="show-pass" />
                            </button>
                        </div>
                    </div>
                    <div class="warning" v-if="showValidationError && form.password == ''">
                        Please enter Password
                    </div>
                    <ul class="invalid-password-message" v-if="passwordValidation">
                        <li>Password must be of minimum 8 characters and maximum 20 characters </li>
                        <li>Password must contains alphabets and numbers</li>
                    </ul>


                    <div class="get-mob for">
                        <input v-if="showConfirmPassword" type="text" v-model="form.confirmPassword" class="form-control" id="inputPassword2" placeholder="confirm Password" />
                        <input v-else type="password" v-model="form.confirmPassword" class="form-control" id="inputPassword2" placeholder="confirm Password" />
                           
                        <div class="rug-img-input">
                            <button class="paasword-eye show-pasword" @click.prevent="showConfirmPassword =!showConfirmPassword">
                                <img v-if="showConfirmPassword" src="@/assets/images/hide-pasword.webp" alt="show-pass" />
                                <img v-else src="@/assets/images/show-pasword.webp" alt="show-pass" />
                            </button>
                        </div>
                    </div>

                    <div class="warning" v-if="showValidationError && form.confirmPassword == ''">Please enter Confirm password.</div>
                    <div class="warning" v-if="passwordValidation">password and confirm passwords are not matched.</div>

                    <div class="form-group">
                        <a @click="signUpCall()" class="btn btn-submit btn-login active" v-if="getOtp().length == 6">
                            SUBMIT
                        </a>
                        <a v-else class="btn btn-submit btn-login">
                            SUBMIT
                        </a>
                    </div>
                    <div class="for-pass">
                        <p class="login-with-acc u-code">Existing User? &nbsp;
                            <router-link to="/login">CLICK HERE</router-link>
                        </p>
                    </div>
                </form>
             </div>
         </div>
      </section>
      <section class="slide-bouns" loading="lazy">
         <div class="container">
            <div class="slide-banner-item">
               <div class="slide-bouns-banner-item">
                    <div class="slide-bonus-banner"> <img src="@/assets/images/wbonus1aug.webp" alt="login" /></div>
                     <div class="slide-bonus-banner">  <img src="@/assets/images/cbonus1aug.webp" alt="login" /></div>
                     <div class="slide-bonus-banner"> <img src="@/assets/images/withdrwals1aug.webp" alt="login" /></div>
                     <div class="slide-bonus-banner"> <img src="@/assets/images/wbonus1aug.webp" alt="login" /></div>
                     <div class="slide-bonus-banner"> <img src="@/assets/images/loyaltyc1.webp" alt="login" /></div>
                     <div class="slide-bonus-banner"> <img src="@/assets/images/wbonus1aug.webp" alt="login" /></div>
                     <div class="slide-bonus-banner">  <img src="@/assets/images/tsuport1.webp" alt="login" /></div>
                     <div class="slide-bonus-banner">  <img src="@/assets/images/cbonus1aug.webp" alt="login" /></div>
                     <div class="slide-bonus-banner">  <img src="@/assets/images/withdrwals1aug.webp" alt="login" /></div>
                     <div class="slide-bonus-banner">   <img src="@/assets/images/loyaltyc1.webp" alt="login" /></div>
             </div>
               <div class="swip-icon">
                  <img src="@/assets/images/SwipeIcon.webp" alt="" />
               </div>
            </div>
         </div>
      </section>
  </div>
</template>

<script>
import api from '../services/api';
import * as apiName from '../services/urls';

export default {
   name:'SignUp',
   inject:['recaptcha'],
   data() {
        return {
            form: {
                code: this.siteSettings && this.siteSettings?.country_code ? this.siteSettings?.country_code : "91",
                mobileNo: "",
                otp: [],
                userName: "",
                password: "",
                confirmPassword: "",
                referCode: this.getQueryParam("refer_code", window.location.href),
                captchaText:''
            },
            showValidationError: false,
            showErrorMobile: false,
            passwordValidation: false,
            loadingSignUp: false,
            loadingOtp: false,
            isOtpApiCalled: false,
            resendOtp: false,
            timerRunning: false,
            minutes: 2,
            seconds: 0,
            showPassword:false,
            showConfirmPassword:false,

        };
    },
    computed: {
        siteSettings() {
            return this.$store.getters.siteSettings
        }
    },
    created(){
        if(this.siteSettings && this.siteSettings?.business_type == 1){
            this.$router.push('/login')
        }
    },
    methods: {
        refreshData() {
            this.form = {
                code: 91,
                mobileNo: "",
                otp: [],
                userName: "",
                password: "",
                confirmPassword: "",
                referCode: ""
            },
                this.showValidationError = false,
                this.showErrorMobile = false,
                this.passwordValidation = false,
                this.isOtpApiCalled = false,
                this.resendOtp = false,
                this.resendOtpTimer = 0,
                this.timerRunning = false,
                this.minutes = 0,
                this.seconds = 0
        },
        sendOtpCall() {
            if (!this.loadingOtp) {
                if (this.form.mobileNo == "") {
                    this.showErrorMobile = true;
                }
                else {
                    this.sendOtpServiceCall();
                }
            }
        },
        async sendOtpServiceCall() {
            this.loadingOtp = true;
            let recaptchaToken = await this.recaptcha();
            api.post(apiName.SEND_OTP, {
                mobile_number: this.form.mobileNo,
                cncode: this.form.code,
                type:'register',
                recaptcha_token: recaptchaToken
            }).then(response => {
                this.loadingOtp = false;
                if (response) {
                    if (response.status == 200) {
                        this.isOtpApiCalled = true;
                        this.showSuccessModalFunc(response.data.message)
                        this.resendOtp = true;
                        this.timerRunning = true;
                        this.minutes = 2;
                        this.seconds = 0
                        this.setResendTimer();
                    }
                }
            }).catch(error => {
                this.loadingOtp = false;
                if (error)
                    this.showErrorModalFunc(error[0])
            });
        },
        signUpCall() {
         console.log('first call')
            if (!this.loadingSignUp) {
                var regex = /^(?=.*?[a-z])(?=.*?[0-9]).{8,20}$/;
                let o = this.getOtp();
                this.passwordValidation = false;
                if (this.form.mobileNo == "" ) {
                    this.showErrorMobile = true;
                }
                else if (o.length != 6 || this.form.userName == '' || this.form.password == '' || this.form.confirmPassword == '') {
                    this.showValidationError = true;
                }
                else if(!regex.test(this.form.password)){
                    this.passwordValidation = true;
                }
                else if (this.form.password != this.form.confirmPassword) {
                    this.passwordValidation = true;
                }
                else {
                  console.log('call')
                    this.signUpServiceCall();
                }
            }
        },
        async signUpServiceCall() {
            this.loadingSignUp = true;
            let isDownLine = this.getQueryParam("downline", window.location.href);
            let recaptchaToken = await this.recaptcha();
            let data = {
                "userid": this.form.userName,
                "phone": this.form.mobileNo,
                "password": this.form.password,
                "otp": this.getOtp(),
                "isDownLine": isDownLine ? isDownLine : false,
                "refer_code": this.form.referCode,
                "recaptcha_token": recaptchaToken,
                "cncode" : this.form.code,
            };
            api.post(apiName.REGISTER, data).then(response => {
                this.loadingSignUp = false;
                if (response) {
                    if (response.status == 200) {
                        if (response.data.status == 0) {
                            this.showErrorModalFunc(response.data.debug[0]);
                        } else {
                            this.showSuccessModalFunc(response.data.message);
                            this.refreshData();
                            this.$router.push('/login')
                        }
                    }
                }
            }).catch(error => {
                this.loadingSignUp = false;
                if (error)
                    this.showErrorModalFunc(error[0]);
            });
        },
        setResendTimer() {
            const countdown = () => {
                if (this.minutes === 0 && this.seconds === 0) {
                    this.timerRunning = false;
                    // Timer has expired, you can perform any action here
                } else {
                    if (this.seconds === 0) {
                        this.minutes--;
                        this.seconds = 59;
                    } else {
                        this.seconds--;
                    }
                    setTimeout(countdown, 1000);
                }
            };

            setTimeout(countdown, 1000);
        },
        formatTime(value) {
            return value < 10 ? `0${value}` : value;
        },
        getOtp() {
            return this.form.otp.toString().replaceAll(',', '');
        },
        getQueryParam(paramName, url) {
            if (!url) url = location.href;
            paramName = paramName.replace(/[\[]/, "\\\[").replace(/[\]]/, "\\\]");
            var regexS = "[\\?&]" + paramName + "=([^&#]*)";
            var regex = new RegExp(regexS);
            var results = regex.exec(url);
            return results == null ? null : results[1];
        },
         showErrorModalFunc(message) {
            this.$emit('error-modal', message);
        },
        showSuccessModalFunc(message) {
            this.$emit('success-modal', message);
        },

    },
}
</script>

<style>

</style>