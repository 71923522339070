<template>
<aside class="my-bets-footer">
    <div class="offcanvas offcanvas-end" data-bs-scroll="true" tabindex="-1" id="mybetSideMenu" aria-labelledby="mybetSideMenu">
        <div class="offcanvas-menu">
            <div class="swich-button">
                <div class="click-bet">
                    <div class="one-bet">
                        <div class="swich-input">
                            <input type="checkbox" v-model="clickBetCheck" @change="betCheckChangeFunc()" />
                            <label class="form-check-label" for="flexCheckDefault">
                                1-Click Bet
                            </label>
                        </div>
                        <span v-if="clickBetCheck && getClickBetSelectedValue()">
                            <label class="clickBet-value-label">{{ getKFormatter(getClickBetSelectedValue()) }}</label>
                        </span>
                    </div>
                    <!-- <div class="any-odds">
                        <div class="swich-input">
                            <input type="checkbox" />
                            <label class="form-check-label" for="flexCheckDefault">
                                ACCEPT any ODDS From BM
                            </label>
                        </div>
                    </div> -->
                </div>
                <div class="edit-stack">
                    <div class="before-edit-stake" v-if="clickBetCheck && !showEditStakes">
                        <div class="edit-stake-btn">
                            <form >
                                <button @click.prevent="selectInputValue(0)" class="edit-text" :class="{ 'active-color': inputvalue?.[0].isSelected }">
                                    {{inputvalue?.[0].value}}
                                </button>
                                <button @click.prevent="selectInputValue(1)" class="edit-text" :class="{ 'active-color': inputvalue?.[1].isSelected }">
                                    {{inputvalue?.[1].value}}
                                </button>
                                <button @click.prevent="selectInputValue(2)" class="edit-text" :class="{ 'active-color': inputvalue?.[2].isSelected }">
                                    {{inputvalue?.[2].value}}
                                </button>
                                <button @click.prevent="selectInputValue(3)" class="edit-text" :class="{ 'active-color': inputvalue?.[3].isSelected }">
                                    {{inputvalue?.[3].value}}
                                </button>
                                <button class="edit-text edit-btn" @click.prevent="showEditStakes=!showEditStakes">
                                    Edit Stakes
                                    <div class="img-edit">
                                        <img src="@/assets/images/feather-orderslip.webp" alt="" />
                                    </div>
                                </button>
                            </form>
                        </div>
                        <div class="edit-input">
                            <button class="save-btn" @click.prevent="saveClickBetValue()">
                            save
                            <div class="img-edit">
                                <img src="@/assets/images/check-white.webp" alt="" />
                            </div>
                            </button>
                        </div>
                    </div>
                    <div class="after-edit-stake" v-if="showEditStakes">
                        <div class="edit-stake-btn" v-if="inputvalue">
                            <form action="">
                                <div class="edit-input">
                                    <input @click="selectInputValue(0)" type="number" v-model="inputvalue[0].value" :class="{ 'border-input': inputvalue?.[0].isSelected }" />
                                </div>
                                <div class="edit-input">
                                    <input @click="selectInputValue(1)" type="number" v-model="inputvalue[1].value" :class="{ 'border-input': inputvalue?.[1].isSelected }" />
                                </div>
                                <div class="edit-input">
                                    <input @click="selectInputValue(2)" type="number" v-model="inputvalue[2].value" :class="{ 'border-input': inputvalue?.[2].isSelected }" />
                                </div>
                                <div class="edit-input">
                                    <input @click="selectInputValue(3)" type="number" v-model="inputvalue[3].value" :class="{ 'border-input': inputvalue?.[3].isSelected }" />
                                </div>
                                <div class="edit-input">
                                    <button class="save-btn" @click.prevent="saveClickBetValue()">
                                    save
                                    <div class="img-edit">
                                        <img src="@/assets/images/check-white.webp" alt="" />
                                    </div>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <section class="edit-cancel-stake">
                    <div class="header-stake">
                    <div class="bet-slip">
                        Stakes
                    </div>
                    <div class="edit-canel">
                        <button class="edit-cancel-btn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                            <div class="edit-stake">
                                <div class="edit-add">
                                <span> Edit Stakes</span>
                                <div class="stake-img-edit">
                                    <img src="@/assets/images/gear-edit-stake-red.webp" alt="" />
                                </div>
                                </div>
                            </div>

                            <div class="cancel-stake">
                                <div class="edit-add" ref="close_button" @click="resetChips()">
                                    <span>Cancel Editing</span>
                                    <div class="stake-img-edit">
                                        <img src="@/assets/images/x-edit-stake-red.webp" alt="" />
                                    </div>
                                </div>
                            </div>
                        </button>
                    </div>
                    </div>
                    <div class="stake-body">
                    <div class="collapse" id="collapseExample">
                        <div class="card card-body">
                            <div class="alin-edit-stake" v-if="chips">
                                <div class="edit-stake-btn">
                                <form action="">
                                    <div class="edit-input">
                                        <input type="number" @change="chips.chip_name_1 = getKFormatter(chips.chip_val_1)"
                                            v-model="chips.chip_val_1">
                                    </div>
                                    <div class="edit-input">
                                        <input type="number" @change="chips.chip_name_2 = getKFormatter(chips.chip_val_2)"
                                            v-model="chips.chip_val_2">
                                    </div>
                                    <div class="edit-input">
                                        <input type="number" @change="chips.chip_name_3 = getKFormatter(chips.chip_val_3)"
                                            v-model="chips.chip_val_3">
                                    </div>
                                    <div class="edit-input">
                                        <input type="number" @change="chips.chip_name_4 = getKFormatter(chips.chip_val_4)"
                                            v-model="chips.chip_val_4">
                                    </div>
                                    <div class="edit-input">
                                        <input type="number" @change="chips.chip_name_5 = getKFormatter(chips.chip_val_5)"
                                            v-model="chips.chip_val_5">
                                    </div>
                                    <div class="edit-input">
                                        <input type="number" @change="chips.chip_name_6 = getKFormatter(chips.chip_val_6)"
                                            v-model="chips.chip_val_6">
                                    </div>
                                    <div class="edit-input">
                                        <input type="number" @change="chips.chip_name_7 = getKFormatter(chips.chip_val_7)"
                                            v-model="chips.chip_val_7">
                                    </div>
                                    <div class="edit-input">
                                        <input type="number" @change="chips.chip_name_8 = getKFormatter(chips.chip_val_8)"
                                            v-model="chips.chip_val_8">
                                    </div>
                                    <div class="edit-input">
                                        <button class="save-btn" @click.prevent="callChangeStakeValue()">
                                            save
                                            <div class="img-edit">
                                            <img src="@/assets/images/check-white.webp" alt="" />
                                            </div>
                                        </button>
                                    </div>
                                </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </section>

                <div class="tabs-btn">
                    <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                    <li class="nav-item" role="presentation">
                        <button class="nav-link active" id="pills-ummatch-tab" data-bs-toggle="pill" data-bs-target="#pills-ummatch" type="button" role="tab" aria-controls="pills-ummatch" aria-selected="true">
                            Unmatched
                            <div class="status">0</div>
                        </button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">
                            Matched
                            <div class="status">{{totalOpenBetsCount}}</div>
                        </button>
                    </li>
                    </ul>
                    <div class="tab-content" id="pills-tabContent">
                    <div class="tab-pane fade show active" id="pills-ummatch" role="tabpanel" aria-labelledby="pills-ummatch-tab">
                        <div class="text-center">
                            <NoDataFound/>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                        <template v-if="openBetsList && openBetsList.length > 0">
                            <div class="matched-bet-outer">
                                <div class="matched-bet-inner">
                                    <h3>MATCHED BET</h3>
                                    <div class="select-event flex-box">
                                    <p>Selected Event:</p>
                                    <div class="enent-drop">
                                        <select v-model="filterEventName" class="form-select" aria-label="Default select example">
                                            <option value="all">All</option>
                                            <option :value="event.event_id" v-for="(event, event_index) in openBetsList" :key="event.event_id">
                                                {{event.event_name}} &nbsp;
                                                <div class="bet-count">{{event?.bet_count}}</div>
                                            </option>
                                        </select>
                                    </div>
                                    </div>
                                    <div class="place-bet-tab">
                                    <ul class="nav nav-pills" id="pills-tab" role="tablist">
                                        <li class="nav-item" role="presentation">
                                            <button class="nav-link active" id="pills-CONSOLIDATED-tab" data-bs-toggle="pill" data-bs-target="#pills-CONSOLIDATED" type="button" role="tab" aria-controls="pills-CONSOLIDATED" aria-selected="true">
                                                CONSOLIDATED
                                            </button>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                            <button class="nav-link" id="pills-AVERAGE-tab" data-bs-toggle="pill" data-bs-target="#pills-AVERAGE" type="button" role="tab" aria-controls="pills-AVERAGE" aria-selected="false" tabindex="-1">AVERAGE</button>
                                        </li>
                                        <!-- <li class="nav-item" role="presentation">
                                            <button class="nav-link" id="pills-EVENT-tab" data-bs-toggle="pill" data-bs-target="#pills-EVENT" type="button" role="tab" aria-controls="pills-EVENT" aria-selected="false" tabindex="-1">EVENT DATE</button>
                                        </li> -->
                                    </ul>
                                    <div class="tab-content" id="pills-tabContent">
                                        <div class="tab-pane fade show active" id="pills-CONSOLIDATED" role="tabpanel" aria-labelledby="pills-CONSOLIDATED-tab">
                                            <div class="place-bet-inner" v-for="(event, event_index) in filterEvents" :key="event_index">
                                                <h2 class="bet-slip-head" v-if="event.main_market?.length || event.bookmakers?.length || event.fancies?.length">
                                                    {{ event.event_name }}
                                                </h2>
                                                <template v-for="(type, t_index) in groupByIds(event?.main_market)"
                                                    :key="t_index" v-if="event.main_market?.length > 0">
                                                    <div class="mat-Odds">
                                                        Match Odds
                                                    </div>

                                                    <div class="table-responsive">
                                                        <table class="table">
                                                            <thead class="table-col">
                                                                <tr>
                                                                    <th>SELECTION</th>
                                                                    <th>ODDS</th>
                                                                    <th>STAKE</th>
                                                                    <th>P&amp;L</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr v-for="(item, item_index) in type" :key="item_index">
                                                                    <td><span class="county-name">{{item.selection}}</span></td>
                                                                    <td><span>{{ item.odds }}</span></td>
                                                                    <td><span>{{ item.stake }}</span></td>
                                                                    <td><span class="profit-add w-bold">{{ item.pl }}</span></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </template>

                                                <template v-for="(type, t_index) in groupByIds(event?.bookmakers)"
                                                    :key="t_index" v-if="event.bookmakers?.length > 0">
                                                    <div class="mat-Odds">
                                                        Bookmaker
                                                    </div>

                                                    <div class="table-responsive">
                                                        <table class="table">
                                                            <thead class="table-col">
                                                                <tr>
                                                                    <th>SELECTION</th>
                                                                    <th>ODDS</th>
                                                                    <th>STAKE</th>
                                                                    <th>P&amp;L</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr v-for="(item, item_index) in type" :key="item_index">
                                                                    <td><span class="county-name">{{item.selection}}</span></td>
                                                                    <td><span>{{ item.odds }}</span></td>
                                                                    <td><span>{{ item.stake }}</span></td>
                                                                    <td><span class="profit-add w-bold">{{ item.pl }}</span></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </template>

                                                <template v-for="(type, t_index) in groupByIds(event?.fancies)"
                                                    :key="t_index" v-if="event.fancies?.length > 0">
                                                    <div class="mat-Odds">
                                                        {{type?.[0].selection}}
                                                    </div>
                                                    <div class="table-responsive">
                                                        <table class="table">
                                                            <thead class="table-col">
                                                                <tr>
                                                                    <th>SELECTION</th>
                                                                    <th>ODDS</th>
                                                                    <th>STAKE</th>
                                                                    <th>P&amp;L</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr v-for="(item, item_index) in type" :key="item_index">
                                                                    <td><span class="county-name">{{item.selection}}</span></td>
                                                                    <td><span>{{ item.odds }}</span></td>
                                                                    <td><span>{{ item.stake }}</span></td>
                                                                    <td><span class="profit-add w-bold">{{ item.pl }}</span></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </template>
                                            </div>
                                        </div>

                                            <!-- Average Odds -->
                                        <div class="tab-pane fade" id="pills-AVERAGE" role="tabpanel" aria-labelledby="pills-AVERAGE-tab">
                                            <div class="place-bet-inner" v-for="(event, event_index) in filterEvents" :key="event_index">
                                                <h2 class="bet-slip-head" v-if="event.main_market?.length || event.bookmakers?.length || event.fancies?.length">
                                                    {{ event.event_name }}
                                                </h2>
                                                <template v-for="(type, t_index) in groupByIds(event?.main_market)"
                                                    :key="t_index" v-if="event.main_market?.length > 0">
                                                    <div class="mat-Odds">
                                                        Match Odds
                                                    </div>
                                                    <div class="table-responsive">
                                                        <table class="table">
                                                            <thead class="table-col">
                                                                <tr>
                                                                    <th>ODDS</th>
                                                                    <th>STAKE</th>
                                                                    <th>P&amp;L</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td><span>{{ getAverageOdds(type) }}</span></td>
                                                                    <td><span>{{ getAverageStake(type) }}</span></td>
                                                                    <td><span class="profit-add w-bold">{{ getAveragePL(type) }}</span></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </template>
                                                <template v-for="(type, t_index) in groupByIds(event?.bookmakers)"
                                                    :key="t_index" v-if="event.bookmakers?.length > 0">
                                                    <div class="mat-Odds">
                                                    Bookmaker
                                                    </div>
                                                    <div class="table-responsive">
                                                        <table class="table">
                                                            <thead class="table-col">
                                                                <tr>
                                                                    <th>ODDS</th>
                                                                    <th>STAKE</th>
                                                                    <th>P&amp;L</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td><span>{{ getAverageOdds(type) }}</span></td>
                                                                    <td><span>{{ getAverageStake(type) }}</span></td>
                                                                    <td><span class="profit-add w-bold">{{ getAveragePL(type) }}</span></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </template>
                                                <template v-for="(type, t_index) in groupByIds(event?.fancies)"
                                                    :key="t_index" v-if="event.fancies?.length > 0">
                                                    <div class="mat-Odds">
                                                        {{type?.[0].selection}}
                                                    </div>
                                                    <div class="table-responsive">
                                                        <table class="table">
                                                            <thead class="table-col">
                                                                <tr>
                                                                    <th>ODDS</th>
                                                                    <th>STAKE</th>
                                                                    <th>P&amp;L</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td><span>{{ getAverageOdds(type) }}</span></td>
                                                                    <td><span>{{ getAverageStake(type) }}</span></td>
                                                                    <td><span class="profit-add w-bold">{{ getAveragePL(type) }}</span></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </template>
                                            </div>
                                        </div>
                                        <div class="tab-pane fade" id="pills-EVENT" role="tabpanel" aria-labelledby="pills-EVENT-tab">...</div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            <div class="text-center">
                                <NoDataFound/>
                            </div>
                        </template>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</aside>
</template>

<script>
import { getUtcTimeStampFromDate, convertUTCDateToLocalDate, getSubtractedDaysDate } from '@/shared/utils/date-time';
import { AUTH_TOKEN } from '@/shared/constants/cookies-const';
import api from '@/shared/services/api';
import NoDataFound from '@/shared/components/no-data/NoDataFound.vue';
import * as apiName from '@/shared/services/urls';
import * as types from '@/modules/statements/utils/constants'
import { kFormatter } from '@/shared/utils/formatter';
import moment from 'moment';
export default {
    name: "MybetSideMenu",
    components:{
        NoDataFound
    },
    data() {
        return {
            chips: null,
            chipsDummy: null,
            userData: null,
            clickBetCheck: false,
            inputvalue: null,
            showEditStakes:false,

            loading: false,
            serviceData: null,
            totalPage: null,
            pageNo: 1,
            pageEntries: 50,
            filterOption: {
                sportId: types.WIN_LOSS_TYPES[0].id,
                sportId1: '',
                betType: types.BET_TYPES[0].id,
                reportType: types.REPORT_TYPES[0].id,
                reportType1: types.SPORTS_TYPES[0].id,
                gameType: types.GAME_TYPES[0].id,
                gameSubType: types.SPORTS_SUB_TYPES[0].id,
                fromDate: moment(new Date(getSubtractedDaysDate(new Date(1970, 0, 1), 0))).format('YYYY-MM-DD'),
                toDate: moment(new Date()).format('YYYY-MM-DD')
            },
            selectedType:'1',
            openBetsList: null,
            filterEventName:'all',
            totalOpenBetsCount:null
        }
    },
    computed:{
        filterEvents(){
            console.log(this.filterEventName)
            if(this.filterEventName =='all'){
                return this.openBetsList 
            }else{
                var newList = this.openBetsList.filter(item => item.event_id == this.filterEventName)
                return newList
            }
        }
    },
    created(){
        this.getOpenBetsServiceCall()
    },
    mounted() {
        this.chips = { ...this.$store?.getters?.chips };
        this.chipsDummy = { ...this.$store?.getters?.chips };
        this.userData = this.$store?.getters?.stateUser;

        if(this.getClickBetValue()){
            this.clickBetCheck=true
        }
        this.userData = this.$store?.getters?.stateUser;


        if (this.getClickBetValue()) {
            this.inputvalue = this.getClickBetValue();
        }
        else {
            this.inputvalue = [
                {
                    value: 1000,
                    isSelected: true,
                },
                {
                    value: 2000,
                    isSelected: false,
                },
                {
                    value: 3000,
                    isSelected: false,
                },
                {
                    value: 4000,
                    isSelected: false,
                }
            ]
        }
    },
    methods: {
        callChangeStakeValue() {
            var chipValueList=[]
            for(var i in this.chips){
                if(i.includes('chip_val')){
                    chipValueList.push(this.chips[i])
                }
            }
            console.log(this.hasDuplicates(chipValueList))
            if(this.hasDuplicates(chipValueList)){
                this.showErrorModalFunc('duplicate stake values are not allowed');
                return
            }
            
            let chipsData = {
                "chips": {
                    ...this.chips
                }
            }
            let headers = {
                'Authorization': `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
            }
            this.loading = true;
            api.post(apiName.CHANGE_STAKE_VALUES, chipsData, { headers }).then(response => {
                this.loading = false;
                if (response && response.status == 200) {
                    this.$store.dispatch('setChips', this.chips);
                    this.showSuccessModalFunc('Saved successfully.')
                    this.$emit('save-btn');
                    this.$refs.close_button.click();
                } else {

                }
            }).catch(error => {
                this.loading = false;
                if (error) {
                    this.showErrorModalFunc(error[0]);
                }
            });
        },
        getOpenBetsServiceCall() {
            if (!this.checkIsLogin()) {
                return
            }
            let headers = {
                'Authorization': `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
            }
            this.loading = true;
            let data = {
                "type": 1,
                "game_type": 1,
                "game_sub_type": 'ALL',
                "page_entries": this.pageEntries,
                "fromtimestamp": moment(new Date(getSubtractedDaysDate(new Date(), 5))).format('X'),
                "totimestamp": moment(new Date()).format('X')
            }

            api.post(apiName.OPEN_BETS_EVENT_LISTING + '?page=' + this.pageNo, data,{headers}).then(response => {
                this.loading = false;
                this.openBetsList = [];
                this.totalPage = 0;
                if (response) {
                    if (response.status == 200) {
                        if (response?.data?.status == 0) {
                            this.showErrorModalFunc(response?.data?.debug[0]);
                        } else {
                            this.serviceData = response?.data?.data;
                            this.totalPage = this.serviceData?.last_page;
                            this.openBetsList =  [...response?.data?.data?.data];

                            this.totalOpenBetsCount = 0;

                            this.openBetsList.forEach(obj => {
                                this.totalOpenBetsCount+=obj.bet_count
                            });

                        }
                    }
                }
            }).catch(error => {
                this.loading = false;
                if (error) {
                    this.showErrorModalFunc(error[0]);
                }
            });
        },

         hasDuplicates(arr) {
            let uniqueSet = new Set(arr.map(item => String(item)));
            return uniqueSet.size !== arr.length;
        },
        betCheckChangeFunc() {
            if (this.clickBetCheck) {
                // this.$refs.open_click_bet_value.click();
            }
            else {
                this.$store.dispatch('setClickBetValue', null);
            }
        },
        getClickBetValue() {
            return this.$store.getters.clickBetValue ? [...this.$store.getters.clickBetValue] : null;
        },
        getClickBetSelectedValue() {
            let inputValues = this.getClickBetValue();
            if (inputValues)
                return inputValues.filter(item => item.isSelected)[0].value;
        },
        selectInputValue(index) {
            for (let i = 0; i < this.inputvalue.length; i++) {
                this.inputvalue[i].isSelected = false;
            }
            this.inputvalue[index].isSelected = true;
        },
        saveClickBetValue() {
            this.$store.dispatch('setClickBetValue', this.inputvalue);
            this.$emit('success-modal','Click bet value updated successfully.');
            this.showEditStakes=false
            // this.$refs.close_btn.click();
        },
        checkIsLogin() {
            return this.$store.getters.isAuthenticated;
        },
        isNegative(num) {
            if (Math.sign(num) === -1) {
                return true;
            }
                return false;
        },
        getKFormatter(num) {
            return kFormatter(num);
        },
        resetChips() {
            this.chips = { ...this.$store?.getters?.chips };
        },
        showErrorModalFunc(message) {
            this.$emit('error-modal', message);
        },
        showSuccessModalFunc(message) {
            this.$emit('success-modal', message);
        },
        groupByIds(arr) {
            var marketTypes = {}
            arr?.forEach(item => {
                const marketId = item.market_id;
                if (!marketTypes[marketId]) {
                    marketTypes[marketId] = [];
                }
                marketTypes[marketId].push(item);
            });
            return marketTypes
        },
        getAverageOdds(data) {
            var averageOdds = 0
            for (var i = 0; i < data.length; i++) {
                data[i].odds = parseFloat(data[i].odds)
                averageOdds += (data[i].odds) / data.length
                // console.log(averageOdds)
            }
            return averageOdds.toFixed(2);
        },
        getAverageStake(data) {
            var averageStake = 0
            for (var i = 0; i < data.length; i++) {
                data[i].stake = parseFloat(data[i].stake)
                averageStake += (data[i].stake) / data.length
                // console.log(averageStake)
                // console.log(data.length)
            }
            return Math.round(averageStake);
        },
        getAveragePL(data) {
            var averagePL = 0
            for (var i = 0; i < data.length; i++) {
                data[i].pl = parseFloat(data[i].pl)
                averagePL += (data[i].pl) / data.length
                // console.log(averagePL)
                // console.log(data.length)
            }
            return Math.round(averagePL);
        },
    },
}
</script>

<style>

</style>