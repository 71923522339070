<template>
    <div class="modal fade game-point-note-modal" id="language_selection_pop_up" tabindex="-1" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content" style="border: none;">
                <div class="modal-body">
                    <section class="sub-header-wrapper">
                        <div class="gp-content">
                            <img data-bs-dismiss="modal" class="gp-closeButton" src="@/assets/images/closeButton.webp"
                                alt="">
                            <div id="app_1"> </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </div>

    <div ref="iframe_pop_modal" style="display: none;" data-bs-toggle="modal" data-bs-target="#iframe_pop_modal">
    </div>

    <!-- Game Play Modal Start -->
    <div class="modal fade game-play-modal" id="iframe_pop_modal" tabindex="-1" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div @click="closeModal()" class="modal-dialog modal-fullscreen" style="max-width: 100%;">
            <IFrameModal @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc" v-if="openIframeModal" />
        </div>

    </div>
</template>
<script>

import IFrameModal from '@/modules/games/components/IFrameModal.vue';

export default {
    name: 'GamesPop1',
    data() {
        return {
            openIframeModal: false,
        }
    },
    methods: {
        yesAgreeMethodCall() {
            this.openIframeModal = true;
            this.$refs.iframe_pop_modal.click();
        },
        closeModal() {
            this.openIframeModal = false
        },
        showErrorModalFunc(message) {
            this.$emit('error-modal', message);
        },
        showSuccessModalFunc(message) {
            this.$emit('success-modal', message);
        },
    },
    components: {
        IFrameModal
    }
}
</script>

<style scoped>
#language_selection_pop_up .modal-content{
    background: transparent !important;
}
</style>